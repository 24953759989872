.location-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .location-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .location-info {
    padding: 10px;
  }
  
  .location-info h3 {
    margin: 0;
    font-size: 1.2em;
  }
  
  .location-info p {
    margin: 5px 0;
    color: #666;
  }
  
  .location-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .location-buttons button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    flex: 1;
    margin: 3px;
    border-radius: 4px;
    
  }
  
  .location-buttons button:hover {
    background-color: #0056b3;
  }
  